@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: 0;
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  overflow: hidden;
}

:root {
  --color-primary: #f6aca2;
  --color-secondary: #f49b90;
  --color-tertiary: #f28b7d;
  --color-quaternary: #f07a6a;
  --color-quinary: #ee6352;
  --color-white: #fff;
  --aspect-ratio: 16 / 9;
}

.index {
  text-align: center;
  background-color: black;
  height: 100vh;
}

h1,
h2,
h3 {
  color: white;
  font-family: "Nunito Sans", sans-serif;
}

.video-container {
  position: relative;
}

.video {
  position: fixed;
  bottom: 0;
}
.navbar {
  position: relative;
  padding-top: 60px;
}

.hero {
  position: relative;
  top: 30%; /*this put the element at the bottom*/
  left: 50%; /*this put the element at the center of the x axis*/
  transform: translateX(-50%);
}

.headline {
  font-size: calc(1rem + 1.5vw);
  text-shadow: 3px 3px 0 var(--color-secondary), 5px 5px 0 var(--color-tertiary),
    6px 6px var(--color-quaternary), 7px 7px 0 var(--color-quinary);
}

ul {
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

li {
  list-style: none;
  font-weight: 800;
  text-align: center;
  cursor: pointer;
  color: white;
  text-shadow: 1px 1px 0 var(--color-secondary),
    1.5px 1.5px 0 var(--color-tertiary), 3px 3px var(--color-quaternary),
    4px 4px 0 var(--color-quinary);
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(0.5rem + 1vw);
  text-align: center;
  margin: 0;
  color: var(--color-primary);
  letter-spacing: 0.2rem;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

li:hover {
  text-shadow: 1px 1px 0 var(--color-white), 2px 2px 0 var(--color-tertiary),
    4px 4px var(--color-quaternary), 6px 6px 0 var(--color-quinary);
  color: var(--color-white);
  font-size: calc(0.51rem + 1vw);
  transition: all 0.2s ease-in-out;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: white;
  display: grid;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  font-weight: 800;
  padding-bottom: 10px;
}

a {
  color: var(--color-white);
}

a:hover {
  color: var(--color-quinary);
}
.logo {
  width: 50px;
}

button {
  background-color: transparent;
  border: none;
}

p {
  color: black;
  font-weight: 800;
  text-transform: uppercase;
}

@media (max-width: 800px) {
  ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
  li {
    padding: 5px 5px 5px 5px;
    font-size: calc(1.75rem + 1vw);
  }

  li:hover {
    font-size: calc(1.77rem + 1vw);
  }
  footer p {
    font-size: 6px;
  }
  .navbar {
    padding-top: 100px;
  }
}

@media (min-height: 900px) {
  ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
  }
  li {
    font-size: calc(0.5rem + 1vw);
  }
  li:hover {
    font-size: calc(0.51rem + 1vw);
  }
}

.pod-logo {
  border-radius: 2vh;
  padding: 10px 0px 10px 0px;
  width: 50%;
  left: 50%; /*this put the element at the center of the x axis*/
  transform: translateX(50%);
}

.established {
  text-shadow: 1px 1px 0 var(--color-secondary),
    1.5px 1.5px 0 var(--color-tertiary), 3px 3px var(--color-quaternary),
    4px 4px 0 var(--color-quinary);
  color: var(--color-primary);
  font-size: calc(0.5rem + 0.7vw);
}
